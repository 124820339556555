import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Seo from "../../components/seo";
import { BrowserView } from "react-device-detect";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import {
  MDXLayoutComponents,
  MDXGlobalComponents,
} from "../../components/mdx/";
import { PageHeader, Typography, ConfigProvider } from "antd";
import InfoDrawer from "../../components/ui/InfoDrawer";
import EmbeddedControls from "../../components/ui/EmbeddedControls";
import "antd/dist/antd.variable.min.css";

const { Title } = Typography;

export default function PageTemplate({ data: { mdx } }) {
  useEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: "#0099cc",
      },
    });
  });
  return (
    <ConfigProvider>
      <div
        style={{
          backgroundColor: "#f1f3f5",
          minHeight: "100vh",
          paddingTop: "67px",
          position: "relative",
        }}
      >
        <Seo
          description={`This is an interactive chart made for ${mdx.frontmatter.client}. This chart is made with inFlow interactive charts (www.inflowchart.com). At inFlow we help professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools.`}
          title={`${mdx.frontmatter.title} by ${mdx.frontmatter.client}`}
        />
        <PageHeader
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ced4da",
            height: "67px",
            left: "0",
            padding: "12px 24px",
            position: "fixed",
            top: "0",
            width: "100%",
            zIndex: "15000",
          }}
          title={
            <Title
              style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                marginTop: "6px",
              }}
            >
              {mdx.frontmatter.title}
            </Title>
          }
          subTitle={
            <>
              <BrowserView style={{ marginTop: "11px" }}>
                <a
                  className="hide-for-small"
                  href="https://www.inflowchart.com?utm=examples"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  LTA Antistollingszorg
                </a>
              </BrowserView>
            </>
          }
          extra={[
            <EmbeddedControls slug={mdx.frontmatter.slug} />,
            <InfoDrawer
              added={mdx.frontmatter.added}
              client={mdx.frontmatter.client}
              edit={mdx.frontmatter.edit}
              language={mdx.frontmatter.language}
              shorturl={mdx.frontmatter.shorturl}
              source={mdx.frontmatter.source}
            />,
          ]}
        />
        <MDXProvider
          components={{
            ...MDXLayoutComponents,
            ...MDXGlobalComponents,
          }}
        >
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
        <div data-iframe-height></div>
        <script
          type="text/javascript"
          src="/js/iframeresizer/4.2.9/iframeResizer.contentWindow.min.js"
        ></script>
      </div>
    </ConfigProvider>
  );
}

export const pageQuery = graphql`
  query LayoutClientLtaAntistollingQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        added
        client
        edit
        language
        shorturl
        slug
        source
        title
      }
    }
  }
`;
